.projectContainer {
	margin: 70px 50px 25px 50px;
	display: flex;
	flex-direction: column;

	height: calc(100vh - 95px);

	overflow: hidden;
}

.videoTitle {
	margin: 70px 0 0 0;

	font-family: 'Lora', serif;
	font-size: 4rem;
	font-weight: 400;
}
