.landingContainer {
	margin: 0;

	display: flex;
	justify-content: center;
}

.empty {
	flex: 1;
}

.landingItems {
	margin-top: calc((100vh / 2) - (642px / 2));
	/* 642 is pixel height of items */
	margin-left: 0;
	margin-right: 0;
	margin-bottom: calc((100vh / 2) - (642px / 2));

	max-width: 1000px;
}

a:link {
	text-decoration: none;
}

.linkText {
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 50px;

	font-family: 'Lora', serif;
	font-size: 8rem;
	font-weight: 500;

	color: var(--nord0);
}

.linkText:hover {
	color: var(--nord3);
}
