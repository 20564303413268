.itemContainer {
	display: flex;

	align-items: top;
}

.spacer {
	flex: 2;
}

.title {
	margin: 30px 0;

	font-family: "Lora", serif;
	font-size: 3rem;
	font-weight: 400;
}

.detail {
	margin: 26px 0 0 0;

	font-family: "Raleway", sans-serif;
	font-size: 1.5rem;
	font-weight: 100;
	text-align: right;
}
