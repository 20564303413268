/* Containers */
.mainContainer {
	display: flex;
	margin: 0;
	height: 100vh;
	width: 100%;

	max-width: large;

	justify-content: center;
}

.textContainer {
	margin: 0 0 0 0;
	width: 30%;

	max-width: 1000px;
}

.imageContainer {
	position: relative;
	margin: 0 0 0 5%;

	width: 90%;
	height: auto;
	max-height: 1500px;
}

.image {
	height: auto;
	width: 100%;
}

/* Styles */

.sectionTitle {
	margin-top: 25%;
	font-family: 'Lora', serif;

	font-weight: 400;
	font-size: 8rem;
	text-align: right;
	color: var(--nord0);
}

.sectionTitle:hover {
	color: var(--nord3);
}

.sectionTitleJp {
	margin: 0;
	padding-top: 200px;
	font-family: 'Noto Serif JP', serif;

	font-weight: 400;
	font-size: 8rem;
	text-align: right;
	color: var(--nord0);
	writing-mode: vertical-rl;
}

.sectionTitleJp:hover {
	color: var(--nord3);
}

.descriptionContainer {
	padding-left: 12px;
	border-left: 2px solid gray;

	font-family: 'Raleway', sans-serif;
	font-size: 1.3rem;
	text-align: left;
}
