.mainContainer {
	display: flex;
	margin: 100px 0 0 0;
	padding: 0;

	justify-content: center;
}

.photoContainer {
	margin: 0;

	/* border: red solid; */
	max-width: 1500px;
}
