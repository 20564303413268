.footerContainer {
	margin: 0;

	display: flex;
}

.button {
	/* border: red solid; */

	font-family: 'Raleway', serif;
	font-size: 2rem;
	font-weight: 400;
}

.empty {
	flex: 2;
}
