.headerContainer {
	padding-left: 25px;
	padding-top: 20px;
	padding-right: 25px;
	padding-bottom: 20px;

	display: flex;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	justify-content: center;

	background-color: white;
	/* transform: translateY(0); */
}

.headerContainer.shadow {
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.13);
	transition: box-shadow 0.3s ease-in-out;
}

/* .headerContainer.hidden {
	transform: translateY(-110%);
	transition: transform 0.3s ease;
} */

.middleContainer {
	flex: 2;
	max-width: 2277px;
}

.headerText {
	margin: 0;
	font-family: 'Raleway', sans-serif;
	font-weight: 100;
	font-size: x-large;
	color: var(--nord0);
}

.headerPath {
	margin: 0 0 0 0.5rem;
	font-family: 'Raleway', sans-serif;
	font-weight: 100;
	font-size: medium;
	color: var(--nord0);
}

.headerText:hover {
	color: var(--nord3);
}
